import React from 'react'
import './Home.css'
import "./AllPages.css"

export const Home = () => {

  return (

    <div id='home'>

        <header className = "Shadowed Gradient App-header">

          RUN FOR WATER

        </header>
        
    </div>

  )
}
